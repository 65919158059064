import m from 'mithril'

import Quotes from '../../models/Quotes'

//Either the quote is given, or it is taken randomly from the default QUOTES


const Component = {

    oninit: vnode => {
        // if (vnode.attrs.quote) {
        //     vnode.state.quote = vnode.attrs.quote
        //     console.log(vnode.state.quote)
        // }

        if (!vnode.attrs.quote && !vnode.attrs.loading) {
            vnode.state.quote = Quotes.quotes[Quotes.quotes_i]
            Quotes.quotes_i++
            Quotes.quotes_i = Quotes.quotes_i % Quotes.quotes.length
        }
    },

    view: function (vnode) {

        if (!vnode.state.quote && vnode.attrs.quote && !vnode.attrs.loading) {
            vnode.state.quote = vnode.attrs.quote
        }

        let quote_1
        let quote_2
        let quote_split
        if (!vnode.attrs.loading) {
            quote_split = vnode.state.quote.quote.split('* ')
            quote_1 = quote_split.slice(0, quote_split.length - 1) + '- '
            quote_2 = quote_split[quote_split.length - 1]
        }
        return [

            (!vnode.attrs.loading && vnode.state.quote.code) ?

                m('a.quote_bar',
                    {
                        href: "/onlinereader/" + vnode.state.quote.code,
                        // rel: "nofollow"
                    },

                    // m(funny_border),

                    m('.quote_bar_quote',
                        quote_1,
                        m('u', quote_2)
                    )
                ) :
                m('.quote_bar',

                    m('.quote_bar_quote',
                        vnode.attrs.loading ? m('.word_shimmer', '-') : vnode.state.quote.quote,
                    )
                )
        ]
    }

}


// const funny_border = {
//     view: vnode => {
//         return [

//             m('svg.quote_bar_funny_border',
            
//             m("path", {
//                 "stroke": "none",
//                 // "d": path,
//                 style: 'd: path("' + path + '")',
//                 "fill": vnode.attrs.bg
//             }),

//         ]
//     }
// }

export default Component






