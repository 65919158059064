// Author -Evan Lewis

// Main js script called by index.html
// Front end framework - Mithril

import m, { route } from 'mithril'

import home from './views/pages/home'
import layout from './views/layout'

route.prefix = ''

var root = document.body

let routes = {}


routes['/'] = {
    render: vnode => m(layout, m(home))
}

routes['/:*'] = {
    render: vnode => m(layout, m(home))
}


//Set all routes
//Home is default (libraryofalexandria.com -> /)
route(root, "/", routes);