import m from 'mithril'
import Genres from '../../models/Genres'
import Quotes from '../../models/Quotes'

let gradient_section = {
    view: vnode => {

        let letter_num = Quotes.quotes[0].quote[1].toLowerCase().charCodeAt(0) - 97

        let hue = Math.round(letter_num * 360 / 27)

        let colour = vnode.attrs.genre ? Genres.get_faint_colour(vnode.attrs.genre) : `hsl(${hue}, 100%, 93%)`

        let quote_background = `linear-gradient(to bottom, ${colour}, var(--general_background))`

        let background = vnode.attrs.background || quote_background
        let gradient_style = 'background: ' + background

        if (vnode.attrs.active !== false) {
            return [

                m('.gradient_section',
                    {
                        style: gradient_style
                    },
                    vnode.children
                )
            ]
        }
        else {
            return vnode.children
        }
    }
}

export default gradient_section