

export const capitalise_first = text => {
    if (!text) return ''
    return text[0].toUpperCase() + text.slice(1)
}

export const make_url_friendly = text => {
    if (!text) return text
    text = text.replace(/é/g, 'e')
    return text.replace(/'|"|“|”|‘|’|\?| |\./g, '').toLowerCase()
}

export const my_date_to_readable = my_date => {
    let split_date = my_date.split('-')
    return months[parseInt(split_date[1]) - 1] + ' ' + split_date[2]
}

export const get_publish_date = (book) => {
    return [
        'Published ',
        book?.publish_year_exact === false ? '~' : '',
        Math.abs(book?.publish_year),
        book?.publish_year < 0 ? ' BCE' : ''
    ]
}


export const get_words_info = words => {
    let text = words ? [commaify_words(words), ' words - ', Math.ceil(words / 250), ' minutes'] : ''
    return text
}

//Thanks ChatGPT
export const commaify_words = number => {
    // Check if the input is a number
    if (isNaN(number)) {
        throw new Error('Input must be a number.');
    }

    // Convert the number to a string and split it into an array of digits
    let digits = number.toString().split('');

    // Reverse the array so we can add commas from the right
    digits.reverse();

    // Add commas after every three digits
    for (let i = 3; i < digits.length; i += 3) {
        digits.splice(i, 0, ',');
    }

    // Reverse the array again and join it back into a string
    return digits.reverse().join('');

}

//Parse text for online reader  (asterisk to italics)

// │ to image

//Keep italics within lines

export const parse_text_to_lines = text => {

    if (text == null || text == undefined || text == '') {
        // console.error('No text for parse text method')
        return [[]]
    }

    // Split the text into lines
    const lines = text.split(/[\n]/);

    let phrases = lines.map(line =>
        [{ text: line, italics: false }]
    )

    phrases = phrases.map(line => {
        if (line[0].text && line[0].text.length > 0 && line[0].text != '\r') {
            return line
        }

        return [{ text: '\xa0', italics: false }]
    })

    //Italicise
    phrases = phrases.map(parse_line);

    return phrases
}

export const parse_line = phrases => {
    let original_line = phrases[0].text

    if (original_line.includes('*')) {
        let new_phrases = []
        let italic = false
        let phrase = ''
        for (let i = 0; i < original_line.length; i++) {
            if (original_line[i] !== '*')
                phrase += original_line[i]
            else {
                if (phrase) new_phrases.push({ text: phrase, italic: italic })
                phrase = ''
                italic = !italic
            }
        }

        if (phrase) new_phrases.push({ text: phrase, italic: italic })
        if (new_phrases.some(phrase => phrase.text.includes('*'))) console.error('Leftover Asterisk 1')

        return new_phrases
    }

    //
    else {
        if (original_line[0] == '│') {
            let data = original_line.replace(/│/g, '').split('|')
            let src = data[1]
            let alt = data[0]
            return [{ text: alt, italics: false, image: { src: src, alt: alt } }]
        }
        else {
            return phrases
        }
    }
}


//Parse text for author styles and reviews (asterisk to link)
//Keep is_links within lines

export const do_something_to_style = text => {
    //Split to lines

    let lines

    lines = text.split(/[\n]/).map(line =>
        [{ text: line }]
    )
        .map(line => {
            if (line[0].text && line[0].text.length > 0 && line[0].text != '\r') {
                //  console.log(line);
                return line
            }

            return [{ text: '\xa0' }]
        })

    //is_linkise
    lines = lines.map(phrases => {
        let line = phrases[0].text
        if (!line.includes('*')) return phrases

        let new_phrases = []
        let is_link = false
        let phrase = ''
        for (let i = 0; i < line.length; i++) {
            if (line[i] !== '*')
                phrase += line[i]
            else {
                if (phrase) new_phrases.push({ text: phrase, is_link: is_link })
                phrase = ''
                is_link = !is_link
            }
        }
        if (phrase) new_phrases.push({ text: phrase, is_link: is_link })
        if (new_phrases.some(phrase => phrase.text.includes('*'))) console.error('Leftover Asterisk 2')
        return new_phrases

    });

    return lines
}

export const number_to_text = n => {
    return [
        'zero',
        'one',
        'two',
        'three',
        'four',
        'five',
        'six',
        'seven',
        'eight',
        'nine',
        'ten',
        'thirteen',
        'twelve',
        'thirteen',
        'fourteen',
        'fifteen',
        'sixteen',
        'seventeen',
        'eighteen',
        'nineteen',
        'twenty',
        'twenty-one',
        'twenty-two',
        'twenty-three',
        'twenty-four',
    ][n] || n
}

export const quotify = (text, curly = true) => {
    let quotes = [
        ['"', '"'],
        ['\'', '\''],
        ['“', '”'],
        ['‘', '’']
    ]

    for (let i = 0; i < 4; i++) {
        if (text.startsWith(quotes[i][0]) && text.endsWith(quotes[i][1])) return text
    }

    if (curly) return '“' + text + '”'
    return '"' + text + '"'

}

export const oxford_comma = (list) => {
    if (!list) return ''
    if (!list.length) return ''
    if (list.length == 1) return list[0]
    return list.slice(0, list.length - 1).join(', ') + ' and ' + list[list.length - 1]
}


const months = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"]
