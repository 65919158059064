//Courtesy of https://tablericons.com/
import m from 'mithril'

export const moon = {
    view: vnode => {

        let width = '22'
        let height = '32'

        return [
            m("svg",
                {
                    class: "icon icon-tabler icon-tabler-moon",
                    xmlns: "http://www.w3.org/2000/svg",
                    width: width,
                    height: height,
                    viewBox: "0 0 24 24",
                    "stroke-width": "1.5",
                    stroke: "currentColor",
                    fill: "none",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                },
                [
                    m("path", { stroke: "none", d: "M0 0h24v24H0z", fill: "none" }),
                    m("path", { d: "M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z" })
                ]
            )
        ]
    }
}


export const sun = {
    view: vnode => {
        // let width = '32'
        // let height = '32'

        let width = '22'
        let height = '32'

        return [
            m("svg", {
                class: "icon icon-tabler icon-tabler-sun",
                xmlns: "http://www.w3.org/2000/svg",
                width: width,
                height: height,
                viewBox: "0 0 24 24", "stroke-width": "1.5", stroke: "currentColor", fill: "none", "stroke-linecap": "round", "stroke-linejoin": "round"
            },
                [
                    m("path", { stroke: "none", d: "M0 0h24v24H0z", fill: "none" }),
                    m("circle", { cx: "12", "cy": "12", "r": "4" }),
                    m("path", { d: "M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7" })
                ]
            )
        ]
    }
}


export const info_icon = {
    view: vnode => {

        let width = vnode.attrs.size || 44
        let height = vnode.attrs.size || 44

        return [
            m("svg", {
                class: "icon icon-tabler icon-tabler-info-circle",
                xmlns: "http://www.w3.org/2000/svg",
                width: width,
                height: height,

                viewBox: "0 0 24 24", "stroke-width": "1.5", stroke: "currentColor", fill: "none", "stroke-linecap": "round", "stroke-linejoin": "round"
            },
                [
                    m("path", { stroke: "none", d: "M0 0h24v24H0z", fill: "none" }),
                    m("circle", { cx: "12", "cy": "12", "r": "9" }),
                    m("line", { x1: "12", y1: "8", "x2": "12.01", "y2": "8" }),
                    m("polyline", { "points": "11 12 12 12 12 16 13 16" })
                ]
            )
        ]
    }
}

export const down_circle = {
    view: vnode => {
        return [
            m("svg", {
                class: "icon icon-tabler icon-tabler-arrow-down-circle",
                xmlns: "http://www.w3.org/2000/svg", width: "44", height: "44",
                viewBox: "0 0 24 24", "stroke-width": "1.5",
                stroke: "currentColor", fill: "none", "stroke-linecap": "round", "stroke-linejoin": "round",
                onclick: vnode.attrs.onclick
            },
                [
                    m("path", { stroke: "none", d: "M0 0h24v24H0z", fill: "none" }),
                    m("circle", { cx: "12", "cy": "12", "r": "9" }),
                    m("line", { x1: "8", y1: "12", "x2": "12", "y2": "16" }),
                    m("line", { x1: "12", y1: "8", "x2": "12", "y2": "16" }),
                    m("line", { x1: "16", y1: "12", "x2": "12", "y2": "16" })
                ]
            )
        ]
    }
}
export const up_circle = {
    view: vnode => {
        return [
            m("svg", { class: "icon icon-tabler icon-tabler-arrow-up-circle", xmlns: "http://www.w3.org/2000/svg", width: "44", height: "44", viewBox: "0 0 24 24", "stroke-width": "1.5", stroke: "currentColor", fill: "none", "stroke-linecap": "round", "stroke-linejoin": "round" },
                [
                    m("path", { stroke: "none", d: "M0 0h24v24H0z", fill: "none" }),
                    m("circle", { cx: "12", "cy": "12", "r": "9" }),
                    m("line", { x1: "12", y1: "8", "x2": "8", "y2": "12" }),
                    m("line", { x1: "12", y1: "8", "x2": "12", "y2": "16" }),
                    m("line", { x1: "16", y1: "12", "x2": "12", "y2": "8" })
                ]
            )
        ]
    }
}

export const search_icon = {
    view: vnode => {
        let width = vnode.attrs.size || '1em'
        let height = vnode.attrs.size || '1em'

        return [

            m("svg", {
                class: "icon icon-tabler icon-tabler-search " + (vnode.attrs.class || ''),
                xmlns: "http://www.w3.org/2000/svg",
                width: width,
                height: height,
                viewBox: "0 0 24 24",
                "stroke-width": "1.5",
                stroke: "currentColor",
                fill: "none",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
            },
                [
                    m("path", { stroke: "none", d: "M0 0h24v24H0z", fill: "none" }),
                    m("circle", { cx: "10", "cy": "10", "r": "7" }),
                    m("line", { x1: "21", y1: "21", "x2": "15", "y2": "15" })
                ]
            )
        ]
    }
}

export const filter_icon = {
    view: vnode => {
        return [
            m("svg", {
                "class": "icon icon-tabler icon-tabler-filter",
                "xmlns": "http://www.w3.org/2000/svg",
                "width": "2em",
                "height": "2em",
                "viewBox": "0 0 24 24",
                "stroke-width": "1.5",
                "stroke": "currentColor", "fill": "none", "stroke-linecap": "round", "stroke-linejoin": "round"
            },
                [
                    m("path", { "stroke": "none", "d": "M0 0h24v24H0z", "fill": "none" }),
                    m("path", { "d": "M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5" })
                ]
            )
        ]
    }
}

export const up_arrow = {

    view: vnode => {

        let width = vnode.attrs.resize ? '1em' : '22'
        let height = vnode.attrs.resize ? '1em' : '22'
        let fill = vnode.attrs.fill || 'none'
        let stroke_width = vnode.attrs.stroke_width || '1.5'

        return [
            m("svg", {
                class: (vnode.attrs.class || '') + " icon icon-tabler icon-tabler-caret-up",
                xmlns: "http://www.w3.org/2000/svg",
                width: width,
                height: height,
                viewBox: "0 0 24 24",
                "stroke-width": stroke_width,
                stroke: "currentColor",
                fill: fill, "stroke-linecap": "round", "stroke-linejoin": "round"
            },
                [
                    m("path", { stroke: "none", d: "M0 0h24v24H0z", fill: "none" }),
                    m("path", { d: "M18 15l-6 -6l-6 6h12" })
                ]
            )
        ]
    }
}

export const down_arrow = {
    view: vnode => {

        let width = vnode.attrs.resize ? '1em' : '22'
        let height = vnode.attrs.resize ? '1em' : '22'
        let fill = vnode.attrs.fill || 'none'
        let stroke_width = vnode.attrs.stroke_width || '1.5'

        return [
            m("svg", {
                class: (vnode.attrs.class || '') + " icon icon-tabler icon-tabler-caret-down", xmlns: "http://www.w3.org/2000/svg",
                width: width,
                height: height,
                viewBox: "0 0 24 24",
                "stroke-width": stroke_width,
                stroke: "currentColor", fill: fill, "stroke-linecap": "round", "stroke-linejoin": "round"
            },
                [
                    m("path", { stroke: "none", d: "M0 0h24v24H0z", fill: "none" }),
                    m("path", { d: "M18 15l-6 -6l-6 6h12", "transform": "rotate(180 12 12)" })
                ]
            )
        ]
    }
}

export const drag = {
    view: vnode => {
        return [
            m("svg", { class: "icon icon-tabler icon-tabler-arrow-bar-right", xmlns: "http://www.w3.org/2000/svg", width: "44", height: "44", viewBox: "0 0 24 24", "stroke-width": "1.5", stroke: "currentColor", fill: "none", "stroke-linecap": "round", "stroke-linejoin": "round" },
                [
                    m("path", { stroke: "none", d: "M0 0h24v24H0z", fill: "none" }),
                    m("line", { x1: "20", y1: "12", "x2": "10", "y2": "12" }),
                    m("line", { x1: "20", y1: "12", "x2": "16", "y2": "16" }),
                    m("line", { x1: "20", y1: "12", "x2": "16", "y2": "8" }),
                    m("line", { x1: "4", y1: "4", "x2": "4", "y2": "20" })
                ]
            )
        ]
    }
}

export const tick_icon = {
    view: vnode => {
        return [
            m("svg", {
                class: "icon icon-tabler icon-tabler-check",
                xmlns: "http://www.w3.org/2000/svg",
                width: '1.5em',
                height: "1.5em",
                viewBox: "0 0 24 24",
                "stroke-width": "1.5",
                stroke: "currentColor",
                fill: "none",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
            },
                [
                    m("path", { stroke: "none", d: "M0 0h24v24H0z", fill: "none" }),
                    m("path", { d: "M5 12l5 5l10 -10" })
                ]
            )
        ]
    }
}

export const link = {
    view: vnode => {
        return [
            m("svg", {
                class: "icon icon-tabler icon-tabler-link " + (vnode.attrs.class || ''),
                xmlns: "http://www.w3.org/2000/svg",
                width: "32",
                height: "32",
                viewBox: "0 0 24 24",
                "stroke-width": "1.5",
                stroke: "currentColor",
                fill: "none", "stroke-linecap": "round", "stroke-linejoin": "round"
            },
                [
                    m("path", { stroke: "none", d: "M0 0h24v24H0z", fill: "none" }),
                    m("path", { d: "M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5" }),
                    m("path", { d: "M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5" })
                ]
            )
        ]
    }
}

export const facebook = {
    view: vnode => {
        return [
            m("svg", { "alt": "Share on Facebook", class: "icon icon-tabler icon-tabler-brand-facebook", xmlns: "http://www.w3.org/2000/svg", width: "32", height: "32", viewBox: "0 0 24 24", "stroke-width": "1.5", stroke: "currentColor", fill: "none", "stroke-linecap": "round", "stroke-linejoin": "round" },
                [
                    m("path", { stroke: "none", d: "M0 0h24v24H0z", fill: "none" }),
                    m("path", { d: "M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" })
                ]
            )
        ]
    }
}

export const reddit = {
    view: vnode => {
        return [
            m("svg", { alt: 'Share on reddit', class: "icon icon-tabler icon-tabler-brand-reddit", xmlns: "http://www.w3.org/2000/svg", width: "32", height: "32", viewBox: "0 0 24 24", "stroke-width": "1.5", stroke: "currentColor", fill: "none", "stroke-linecap": "round", "stroke-linejoin": "round" },
                [
                    m("path", { stroke: "none", d: "M0 0h24v24H0z", fill: "none" }),
                    m("path", { d: "M12 8c2.648 0 5.028 .826 6.675 2.14a2.5 2.5 0 0 1 2.326 4.36c0 3.59 -4.03 6.5 -9 6.5c-4.875 0 -8.845 -2.8 -9 -6.294l-1 -.206a2.5 2.5 0 0 1 2.326 -4.36c1.646 -1.313 4.026 -2.14 6.674 -2.14z" }),
                    m("path", { d: "M12 8l1 -5l6 1" }),
                    m("circle", { cx: "19", "cy": "4", "r": "1" }),
                    m("circle", { cx: "9", "cy": "13", "r": ".5", fill: "currentColor" }),
                    m("circle", { cx: "15", "cy": "13", "r": ".5", fill: "currentColor" }),
                    m("path", { d: "M10 17c.667 .333 1.333 .5 2 .5s1.333 -.167 2 -.5" })
                ]
            )
        ]
    }
}

export const share = {
    view: vnode => {
        return [
            m("svg", {
                class: "icon icon-tabler icon-tabler-share",
                xmlns: "http://www.w3.org/2000/svg", width: "44", height: "44",
                viewBox: "0 0 24 24", "stroke-width": "1.5",
                stroke: "currentColor",
                fill: "none", "stroke-linecap": "round", "stroke-linejoin": "round"
            },
                [
                    m("path", { stroke: "none", d: "M0 0h24v24H0z", fill: "none" }),
                    m("circle", { cx: "6", "cy": "12", "r": "3" }),
                    m("circle", { cx: "18", "cy": "6", "r": "3" }),
                    m("circle", { cx: "18", "cy": "18", "r": "3" }),
                    m("line", { x1: "8.7", y1: "10.7", "x2": "15.3", "y2": "7.3" }),
                    m("line", { x1: "8.7", y1: "13.3", "x2": "15.3", "y2": "16.7" })
                ]
            )
        ]
    }
}

export const download = {
    view: vnode => {
        return [
            m("svg", {
                class: "icon icon-tabler icon-tabler-file-download",
                xmlns: "http://www.w3.org/2000/svg",
                width: "44", height: "44",
                viewBox: "0 0 24 24",
                "stroke-width": "1.5",
                stroke: "currentColor",
                fill: "none", "stroke-linecap": "round", "stroke-linejoin": "round"
            },
                [
                    m("path", { stroke: "none", d: "M0 0h24v24H0z", fill: "none" }),
                    m("path", { d: "M14 3v4a1 1 0 0 0 1 1h4" }),
                    m("path", { d: "M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" }),
                    m("line", { x1: "12", y1: "11", "x2": "12", "y2": "17" }),
                    m("polyline", { "points": "9 14 12 17 15 14" })
                ]
            )
        ]
    }
}

export const cog = {
    view: vnode => {
        // let width = '32'
        // let height = '32'

        let width = '22'
        let height = '32'

        return [
            m("svg", {
                class: "icon icon-tabler icon-tabler-settings",
                xmlns: "http://www.w3.org/2000/svg",
                width: width,
                height: height,
                viewBox: "0 0 24 24",
                "stroke-width": "1.5",
                stroke: 'currentColor',
                fill: "none",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
            },
                [
                    m("path", { stroke: "none", d: "M0 0h24v24H0z", fill: "none" }),
                    m("path", { d: "M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z" }),
                    m("circle", { cx: "12", "cy": "12", "r": "3" })
                ]
            )
        ]
    }
}

export const lock_icon = {
    view: vnode => {
        return [
            m("svg", {
                class: "icon icon-tabler icon-tabler-lock",
                xmlns: "http://www.w3.org/2000/svg",
                width: '1.5em',
                height: "1.5em",
                viewBox: "0 0 24 24", "stroke-width": "1.5",
                stroke: "currentColor",

                fill: "none", "stroke-linecap": "round", "stroke-linejoin": "round"
            },
                [
                    m("path", { stroke: "none", d: "M0 0h24v24H0z", fill: "none" }),
                    m("rect", { "x": "5", "y": "11", width: "14", height: "10", "rx": "2" }),
                    m("circle", { cx: "12", "cy": "16", "r": "1" }),
                    m("path", { d: "M8 11v-4a4 4 0 0 1 8 0v4" })
                ]
            )
        ]
    }
}

export const facebook_real = {
    view: vnode => {
        return [
            m("svg", {
                "alt": "Follow on Facebook",
                class: "icon icon-tabler icon-tabler-brand-facebook",
                xmlns: "http://www.w3.org/2000/svg",
                width: "32",
                height: "32",
                viewBox: "0 0 24 24",
                "stroke-width": "1.5",
                stroke: "#4267B2",
                fill: "white",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
            },
                [
                    m("path", { stroke: "none", d: "M0 0h24v24H0z", fill: "#4267B2" }),
                    m("path", { d: "M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" })
                ]
            )
        ]
    }
}

export const book_icon = {
    view: vnode => {
        return [
            m("svg", {
                xmlns: "http://www.w3.org/2000/svg",
                width: "1em",
                height: "1em",
                viewBox: "0 0 15 17.143"
            },
                m("path", {
                    id: "Icon_awesome-book",
                    "data-name": "Icon awesome-book",
                    d: "M15,12.054V.8a.8.8,0,0,0-.8-.8H3.214A3.215,3.215,0,0,0,0,3.214V13.929a3.215,3.215,0,0,0,3.214,3.214H14.2a.8.8,0,0,0,.8-.8V15.8a.81.81,0,0,0-.3-.626,7.467,7.467,0,0,1,0-2.5A.8.8,0,0,0,15,12.054ZM4.286,4.487a.2.2,0,0,1,.2-.2h7.1a.2.2,0,0,1,.2.2v.67a.2.2,0,0,1-.2.2h-7.1a.2.2,0,0,1-.2-.2Zm0,2.143a.2.2,0,0,1,.2-.2h7.1a.2.2,0,0,1,.2.2V7.3a.2.2,0,0,1-.2.2h-7.1a.2.2,0,0,1-.2-.2ZM12.77,15H3.214a1.071,1.071,0,0,1,0-2.143H12.77A12.054,12.054,0,0,0,12.77,15Z", "fill": "currentColor"
                })
            )
        ]
    }
}

export const PDF_icon = {
    view: vnode => {
        return [
            m("svg", { "xmlns": "http://www.w3.org/2000/svg", "width": "1em", "height": "1em", "viewBox": "0 0 13.756 18.342" },
                m("path", { "id": "Icon_awesome-file-pdf", "data-name": "Icon awesome-file-pdf", "d": "M6.516,9.174a3.456,3.456,0,0,1-.072-1.68C6.746,7.494,6.717,8.816,6.516,9.174Zm-.061,1.691a16.53,16.53,0,0,1-1.017,2.246,13.191,13.191,0,0,1,2.253-.785A4.641,4.641,0,0,1,6.455,10.865ZM3.084,15.336c0,.029.473-.193,1.25-1.44A4.951,4.951,0,0,0,3.084,15.336Zm5.8-9.6h4.872v11.75a.858.858,0,0,1-.86.86H.86a.858.858,0,0,1-.86-.86V.86A.858.858,0,0,1,.86,0H8.025V4.872A.862.862,0,0,0,8.884,5.732ZM8.6,11.886a3.6,3.6,0,0,1-1.53-1.927,5.149,5.149,0,0,0,.222-2.3.9.9,0,0,0-1.712-.244,5.957,5.957,0,0,0,.29,2.758,33.64,33.64,0,0,1-1.462,3.074s0,0-.007,0c-.971.5-2.637,1.594-1.952,2.436a1.113,1.113,0,0,0,.77.358c.641,0,1.279-.645,2.189-2.214A20.42,20.42,0,0,1,8.236,13a5.429,5.429,0,0,0,2.293.7.927.927,0,0,0,.706-1.555c-.5-.487-1.945-.347-2.637-.258Zm4.908-8.125L9.995.251A.859.859,0,0,0,9.386,0H9.171V4.585h4.585V4.367A.857.857,0,0,0,13.506,3.762Zm-2.655,9.146c.147-.1-.09-.426-1.533-.322C10.647,13.151,10.851,12.907,10.851,12.907Z", "fill": "currentColor" })
            )
        ]
    }
}

export const star_icon = {
    view: vnode => {
        return [
            m("svg",
                {
                    "xmlns": "http://www.w3.org/2000/svg",
                    "width": "1em",
                    "height": "1em",
                    "viewBox": "0 0 15 14.357"
                },
                m("path", {
                    "id": "Icon_awesome-star",
                    "data-name": "Icon awesome-star",
                    "d": "M8.137.5,6.306,4.211l-4.1.6a.9.9,0,0,0-.5,1.531L4.677,9.227l-.7,4.079a.9.9,0,0,0,1.3.945l3.664-1.926,3.664,1.926a.9.9,0,0,0,1.3-.945l-.7-4.079,2.964-2.888a.9.9,0,0,0-.5-1.531l-4.1-.6L9.746.5A.9.9,0,0,0,8.137.5Z",
                    "transform": "translate(-1.441 0.001)",
                    "fill": "currentColor",
                    stroke: vnode.attrs.stroke
                })
            )
        ]
    }
}

export const eye_icon = {
    view: vnode => {
        return [
            m("svg", {
                "class": "icon icon-tabler icon-tabler-eye",
                "xmlns": "http://www.w3.org/2000/svg",
                "width": '1em',
                "height": '1em',
                "viewBox": "0 0 24 24", "stroke-width": "1.5",
                "stroke": "currentColor", "fill": "none", "stroke-linecap": "round", "stroke-linejoin": "round"
            },
                [
                    m("path", { "stroke": "none", "d": "M0 0h24v24H0z", "fill": "none" }),
                    m("circle", { "cx": "12", "cy": "12", "r": "2" }),
                    m("path", { "d": "M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" })
                ]
            )
        ]
    }
}

export const picture_icon = {
    view: vnode => {
        return [
            m("svg", { class: "icon icon-tabler icon-tabler-photo", xmlns: "http://www.w3.org/2000/svg", width: "44", height: "44", viewBox: "0 0 24 24", "stroke-width": "1.5", stroke: "currentColor", fill: "none", "stroke-linecap": "round", "stroke-linejoin": "round" },
                [
                    m("path", { stroke: "none", d: "M0 0h24v24H0z", fill: "none" }),
                    m("line", { x1: "15", y1: "8", "x2": "15.01", "y2": "8" }),
                    m("rect", { "x": "4", "y": "4", width: "16", height: "16", "rx": "3" }),
                    m("path", { d: "M4 15l4 -4a3 5 0 0 1 3 0l5 5" }),
                    m("path", { d: "M14 14l1 -1a3 5 0 0 1 3 0l2 2" })
                ]
            )
        ]
    }
}

export const tile_icon = {
    view: vnode => {
        return [
            m('.tile_icon',
                [...Array(4)].map((_, i) => [
                    m('.tile_icon_tile')
                ])
            )
        ]
    }
}

export const icons_icon = {
    view: vnode => {
        return [
            m('.icons_icon',
                [...Array(9)].map((_, i) => [
                    m('.icons_icon_tile')
                ])
            )
        ]
    }
}

export const list_icon = {
    view: vnode => {
        return [
            m("svg", {
                class: "icon icon-tabler icon-tabler-list",
                xmlns: "http://www.w3.org/2000/svg",
                width: "1em",
                height: "1em",
                viewBox: "0 0 24 24",
                "stroke-width": "1.5",
                stroke: "currentColor", fill: "none", "stroke-linecap": "round", "stroke-linejoin": "round"
            },
                [
                    m("path", { stroke: "none", d: "M0 0h24v24H0z", fill: "none" }),
                    m("line", { x1: "9", y1: "6", "x2": "20", "y2": "6" }),
                    m("line", { x1: "9", y1: "12", "x2": "20", "y2": "12" }),
                    m("line", { x1: "9", y1: "18", "x2": "20", "y2": "18" }),
                    m("line", { x1: "5", y1: "6", "x2": "5", "y2": "6.01" }),
                    m("line", { x1: "5", y1: "12", "x2": "5", "y2": "12.01" }),
                    m("line", { x1: "5", y1: "18", "x2": "5", "y2": "18.01" })
                ]
            )
        ]
    }
}
