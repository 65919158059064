import m from 'mithril'

const Radio = {

    view: function (vnode) {

        return [
            m('button.remove_button_styling.radio_choice',
                {
                    tabindex: vnode.attrs.tabindex,
                    class: vnode.attrs.active ? 'active' : '',
                    onclick: () => {
                        if (vnode.attrs.onclick) {
                            vnode.attrs.onclick()
                        }
                    }
                },

                m('.radio_text', vnode.attrs.text),
                m('.radio_button', m('.radio_inner_circle')),
            )
        ]
    }
}


export default Radio