import { alphabetise } from "../logic/Sorter"

const Genres = {
    list: [
        {
            code: "war", hue: 1, colour: "hsl(0deg 100% 23%)",
            image: "war",
            image_alt: 'Eight soldiers with rifles on a beach facing an explosion',
            image_position: 'center 63%',
            stories: ['Cain', 'Beware of the Dog', 'An Occurrence at Owl Creek Bridge'],
            images: [
                {
                    src: 'action',
                    alt: 'A small propellor plane flying away from an explosion. Graphite filter.',
                    position: 'center 43%',
                },
                {
                    src: 'war',
                    alt: 'Eight soldiers with rifles on a beach facing an explosion',
                    position: 'center 63%',
                },
                { src: 'civilwar', alt: 'A soldier next to a cannon. Graphite filter.', position: 'bottom' }
            ],
            intro: 'From Russia, to the U.S. and to France, Bierce, Maupassant and others tell of the worst that can come from conflict.'
        },
        {
            code: "horror", hue: 0, colour: "hsl(0deg 100% 0%)",
            image: "hand", image_alt: "A large hand, palm up with tense fingers. Graphite filter.",
            stories: ['The Hand', 'The Black Cat', 'The Horrible'],
            images: [
                { src: 'hand', alt: 'A large hand, palm up with tense fingers. Graphite filter.' },
                { src: 'cult', alt: 'Two men wearing hooded robes. Graphite filter', position: '50% 10%' },
                { src: 'stare', alt: 'The tense, staring eye of a man’s face. Graphite filter' }
            ],
            intro: 'Hands walking on their own, a stalking hound in the night or a sinister black cat. Read after dark.'
        },
        {
            code: "adventure", hue: 20, colour: "hsl(20deg 100% 35%)",
            image: "lost",
            image_alt: 'A man trekking through a vast desert. Graphite filter',
            image_position: 'bottom',

            images: [
                { src: 'lost', alt: 'A speck of a man amongst a vast desert. Graphite filter.', position: 'bottom' },
                { src: 'shotgun', alt: 'A cracked shotgun with smoke rising from the empty barrels. Graphite filter.' },
                { src: 'jungle', alt: 'A jungle of tall, twisting ferns. Graphite filter' }],
            stories: ['The Country of the Blind', 'The Unexpected', 'The Most Dangerous Game'],
            intro: 'Journey through the cold winters of Jack London, the alien landscapes of Clark Ashton Smith, and the exotic jungles of H. G. Wells.'

        },
        {
            code: "childrens", hue: 39, colour: "hsl(39deg 100% 35%)",
            image: "rabbit", image_alt: "A rabbit, looking nervous. Graphite filter.", image_position: '50% 33%',
            images: [
                { src: 'rabbit', alt: 'A nervous looking rabbit. Graphite Filter.', position: '50% 33%' },
                { src: 'spinning', alt: 'A solitary spinning wheel. Graphite filter.', position: '50% 18%' },
                { src: 'nibbling', alt: 'A sparrow eating a breadcrumb. Graphite Filter.', position: '50% 18%' }
            ],
            stories: ['The Emperor’s New Clothes', 'Honor Among Sportsmen', 'Hansel and Gretel'],
            intro: 'Fairy tales and fables of talking rabbits and mischievous children from the sweet-hearted minds of Beatrix Potter and Flora Annie Steel, and the darker thoughts of the Brothers Grimm.'

        },
        {
            code: "tragedy", hue: 190, colour: "hsl(190deg 100% 22%)",
            image: "grave",
            image_alt: 'A stone grave with an wreath shape embossed upon it. Graphite filter.',
            image_position: '50% 10%',
            images: [
                { src: 'lone', alt: 'A lone horserider staring off into the sky. graphite filter.', position: 'bottom' },
                { src: 'grave', alt: 'A gravestone. Graphite filter.', position: '50% 8%' },
                { src: 'acrobat', alt: 'A female acrobat inside a raised ring with a crowd watching. Graphite filter.' }],
            stories: ['“allez!”', 'Misery', 'No Place for a Woman'],
            intro: 'Lost love, failed ambition and stricken families. Steel your heart and read of the depths to which a human’s despair can swim.'
        },
        {
            code: "gothic", hue: 0, colour: "hsl(0deg 0% 34%)",
            image: "gothic", image_alt: "A spired cathedral looming large.",
            images: [
                { src: 'oldtrain', alt: 'A train crossing a bridge and puffing smoke. Graphite filter.' },
                { src: 'masque', alt: 'An expresionless carnival mask. Graphite filter.' },
                { src: 'gothic', alt: 'A spired castle looming high. Graphite filter.' }],
            stories: ['The Signal Man', 'The Cask of Amontillado', 'The Yellow Wallpaper'],
            intro: 'Ghosts from Dickens, and murderers from Poe. Graveyards, medical procedures, plagues, burials and insanity.'

        },
        {
            code: "sci-fi",
            hue: 100,
            // colour: "hsl(100deg 100% 47%)",
            colour: "hsl(100deg 100% 36%)",


            image: "blastoff",
            image_alt: 'A short rocket launching into space amidst smoke. Graphite filter.',
            image_position: 'center 28%',
            images: [
                { src: 'telephone', alt: 'A solitary, colourless rotary telephone resting on a stooll', position: '50% 10%' },
                { src: 'beaker', alt: 'A chemistry beaker. Graphite Filter.' },
                { src: 'needle', alt: 'a drop of liquid hanging from a thin needle-point. Graphite Filter.' }],
            stories: ['2BR02B', 'The Nothing Equation', 'A Guest of Ganymede'],
            intro: 'A distant star, Jupiter’s moon, the near future or the far. Read of the discovery of new worlds and new technologies, and the consequences that they bring.'

        },
        {
            code: "poetry", hue: 39, colour: "hsl(39deg 77% 25%)", image: "four", image_alt: "Four birds in the distance. Graphie Filter.",
            images: [
                { src: 'clouds', alt: 'white clouds in the sky. Graphite Filter' },
                { src: 'drovers', alt: 'Three men with wide-brimmed hats riding horses in the outback.' },
                { src: 'raven', alt: 'A sitting raven. Graphite filter.', position: '50% 10%' }],
            stories: ['Clancy of the Overflow', 'Invictus', 'The Raven'],
            intro: 'The troubled Poe, the dramatic Paterson, the grateful Henley, and others.'
        },
        {
            code: "literary", hue: 240, colour: "hsl(240deg 70% 35%)", image: "anguish", image_alt: "A woman clutching her face.",
            images: [
                { src: 'oldman', alt: 'An old man with a long beard and long hair. Graphite Filter.' },
                { src: 'petersburg', alt: 'A 20th century city with trams and horse-drawn carriages. Graphite filter.' },
                { src: 'hansom', alt: 'A man by a horse-drawn hansom in the snow.' }
            ],
            stories: ['My Uncle Jules', 'Black Fog', 'The Mantle'],
            intro: 'Delve into the imperfect and unfair workings of society and the impious, inner thoughts of the people within them.'
        },
        {
            code: "mystery", hue: 268, colour: "hsl(268deg 100% 22%)", image: "detective", image_alt: "A silhouetted man in a brimmed hat.",
            images: [
                { src: 'cave', alt: 'A cave with a shaft of light coming in from above. Graphite filter' },
                { src: 'detective', alt: 'A man in a bowler hat and a popped collar. Graphite filter' },
                { src: 'wine', alt: 'Drink being poured into a champagne flute . Graphite filter.' }
            ],
            stories: ['Cool Air', 'The Magic Shop', 'The Nameless City'],
            intro: 'Why does the man upstairs never, ever leave his room? Is the man in the magic shop capable of more than mere tricks? What is within the tunnel from whence come screams and wind?'
        },
        {
            code: "fantasy", hue: 330, colour: "hsl(330deg 100% 35%)",
            image: "crown", image_alt: 'A kings crown sitting on a stool. Graphite filter.',
            images: [
                { src: 'prince', alt: 'A lance-wielding man on an armoured horse. Graphite filter', position: '50% 10%' },
                { src: 'braids', alt: 'A cloaked woman touching a tree in the sun. Graphite filter.' },
                { src: 'temple', alt: 'The marbled pillars of a temple. Graphite filter' }],
            stories: ['A Vintage From Atlantis', 'The Pearl of Love', 'Celephaïs'],
            intro: 'Dreams of love and nightmares of monsters. Worlds beneath us, above us, from other dimensions and buried in our thoughts.'

        },
        {
            code: "quirky",

            // hue: 120,
            // colour: "hsl(120deg 100% 22%)",

            hue: 144,
            colour: "hsl(144deg 100% 22%)",


            image: "oven",
            image_alt: "A vintage oven with a teapot on the stove. Graphite filter.",
            image_position: '50% 36%',
            images: [
                { src: 'oven', alt: 'A vintage oven with a teapot on the stove. Graphite filter.' },
                { src: 'bee', alt: 'A bee on its side. Graphie Filter.' },
                { src: 'ford', alt: 'A ford Model T parked on a rural road.' }],
            stories: ['The Man Who Could Imitate a Bee', 'And All the Earth a Grave', 'Johnny Pye and the Fool-Killer'],
            intro: 'Read from stories with scenes of a man running through the night with a wax figurine in a wheelbarrow, an advertisement for a 2 for 1 coffin sale, or a man hearing his own obituary.'
        }
    ].sort((a, b) => alphabetise(a.code, b.code)),

    get_style: hue => {
        if (!hue) return ''
        return 'filter: grayscale(1) invert() sepia() saturate(150%) hue-rotate(' + ((hue + 140) % 360) + 'deg) invert(1) brightness(1.2);'
    },

    get_faint_colour: genre => {
        let hue = Genres.list.find(g => g.code == genre).hue
        let saturation = hue !== 0 ? 100 : 0
        let lightness = hue !== 0 ? 50 : 0
        if (genre == 'gothic') lightness = 50
        let colour = `hsl(${hue}, ${saturation}%, ${lightness}%, 25%)`
        return colour
    }
}

export default Genres