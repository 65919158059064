const Quotes = {}

Quotes.quotes = [
    { quote: `“I don’t know what the first two were, but the third was for death.” * The Monkey’s Paw`, code: 'the-monkeys-paw' },
    { quote: `“May ne’er a noble of thy murd’rous line survive to reach a greater age than thine!” * The Alchemist`, code: 'the-alchemist' },
    { quote: `“For the drover’s life has pleasures that the townsfolk never know.” * Clancy of the Overflow`, code: 'clancy-of-the-overflow' },
    { quote: `“The coffin became the new status symbol.” * And All the Earth a Grave`, code: 'and-all-the-earth-a-grave' },
    { quote: `“No violence, gentlemen—no violence, I beg of you! Consider the furniture!” * The Adventure of the Mazarin Stone`, code: 'the-adventure-of-the-mazarin-stone' },
    { quote: `“Then suddenly I saw it. With only a slight churning to mark its rise to the surface, the thing slid into view above the dark waters.” * Dagon`, code: 'dagon' },
    { quote: `“There’s nothing more humiliating than to look poor among other women who are rich.” * The Necklace`, code: 'the-necklace' },
    { quote: `“But it is better to learn wisdom late than never to learn it at all.” * The Man With the Twisted Lip`, code: 'the-man-with-the-twisted-lip' },
    { quote: `“If Iona’s heart were to burst and his misery to flow out, it would flood the whole world, it seems, but yet it is not seen.” * Misery`, code: 'misery' },
    { quote: `“The old-timer had been very serious in laying down the law that no man must travel alone in the Klondike after fifty below. Well, here he was.” * To Build a Fire`, code: 'to-build-a-fire' },
    { quote: `“She’s not so pretty, anyway,” Frances said. “Anyway, not pretty enough to take a chance breaking your neck looking at her.” * The Girls in their Summer Dresses`, code: 'the-girls-in-their-summer-dresses' },
    { quote: `“It was no wonder—that Armand Aubigny riding by and seeing her there, had fallen in love with her.” * Desiree’s Baby`, code: 'desirees-baby' },
    { quote: `“No one had any reason to be dissatisfied with what he had seen, no one, that is, except for the starvation artist, he alone, always.” * A Hunger Artist`, code: 'a-hunger-artist' },
    { quote: `“If still you think me mad, you will think so no longer when I describe the wise precautions I took for the concealment of the body.” * The Tell-Tale Heart`, code: 'the-tell-tale-heart' },
    { quote: `“Oh, my God! Helen! It was the band! The speckled band!” * The Adventure of the Speckled Band`, code: 'the-adventure-of-the-speckled-band' },
    { quote: `“The room was being redecorated. It was being redecorated as a memorial to a man who had volunteered to die.” * 2BR02B`, code: '2br02b' },
    { quote: `“The thousand injuries of Fortunato I had borne as I best could, but when he ventured upon insult I vowed revenge.” * The Cask of Amontillado`, code: 'the-cask-of-amontillado' },
    { quote: `“Where a horse’s hoofs strike firelight from the flint stones every stride.” * The Man from Snowy River`, code: 'the-man-from-snowy-river' },
    { quote: `“We live on a placid island of ignorance in the midst of black seas of infinity, and it was not meant that we should voyage far.” * The Call of Cthulhu`, code: 'the-call-of-cthulhu' },
    { quote: `“Quoth the Raven ‘Nevermore.’ ” * The Raven`, code: 'the-raven' },
    { quote: `“He was a tiny spark of life in a hostile sea of Nothing and there was no one to help him.” * The Nothing Equation`, code: 'the-nothing-equation' },
    { quote: `“You ask me to explain why I am afraid of a draught of cool air; why I shiver more than others upon entering a cold room.” * Cool Air`, code: 'cool-air' },
    { quote: `“Uplifting an axe, and forgetting, in my wrath, the childish dread which had hitherto stayed my hand, I aimed a blow at the animal.” * The Black Cat`, code: 'the-black-cat' },
    { quote: `“When I went to the door again, daylight was above me, and the ghost was gone.” * The Signal Man`, code: 'the-signal-man' },
    { quote: `“Funny how little interest people took in me when I only wanted to live.” * A Reputation`, code: 'a-reputation' },
    { quote: `“You’d have thought five ostriches would have ruled cheap on an East Indiaman. But the point was, one of ’em had swallowed a diamond.” * A Deal in Ostriches`, code: 'a-deal-in-ostriches' },
    { quote: `“It is strange to be alone, and so cold. To be the last man on earth....” * The Coming of the Ice`, code: 'the-coming-of-the-ice' },
    { quote: `“He can think of nothing, positively nothing, but that flying-machine of his.” * The Argonauts of the Air`, code: 'the-argonauts-of-the-air' },
    { quote: `“I wonder would the apathy of wealthy men endure were all their windows level with the faces of the poor?” * Faces in the Street`, code: 'faces-in-the-street' },
    { quote: `“Hers was the dread that turns the marrow into ice, and the anguish that slays like a bolt of lightning.” * The Gorgon`, code: 'the-gorgon' },
    { quote: `“There still lives one Frenchman who loves the memories of his native land.” * A Friend of Napoleon`, code: 'a-friend-of-napoleon' },
    { quote: `“I am the captain of my soul.” * Invictus`, code: 'invictus' },
    { quote: `“That is not dead which can eternal lie—And with strange aeons even death may die.” * The Nameless City`, code: 'the-nameless-city' },
    { quote: `“I shall fire de big gun!” * The Empire of Ants`, code: 'the-empire-of-the-ants' },
    { quote: `“O, it’s die we must, but it’s live we can.” * Between the Dusk of a Summer Night`, code: 'between-the-dusk-of-a-summer-night' },
    { quote: `“Women are pretty much people, seems to me.” * If I Were a Man`, code: 'if-i-were-a-man' },
    { quote: `“I have but two passions, my comrades. The pursuit of the truffle and the flight from the female.” * Honor Among Sportsmen`, code: 'honor-among-sportsmen' },
    { quote: `“I’ll have the law of you, and I’ll tell your wife, too...” * A Transgression`, code: 'a-transgression' },
    { quote: `“All I lov’d—I lov’d alone.” * Alone`, code: 'alone' },
    { quote: `“There are two squads of us—the lions and the lambs. If you’re a lamb, you’ll come to lie upon these tables like Gray or Jane Galbraith.” * The Body Snatcher`, code: 'the-body-snatcher' },
    { quote: `“But he hasn’t got anything on!” * The Emperor’s New Clothes`, code: 'the-emperors-new-clothes' },
    { quote: `“For that which we accord to even the meanest creature without sense to demand it.” * The Coup De Grâce`, code: 'the-coup-de-grace' },
    { quote: `“No new horror can be more terrible than the daily torture of the commonplace.” * Ex Oblivione`, code: 'ex-oblivione' },
    { quote: '“They said, when he stood up to speak, stars and stripes came right out in the sky.” * The Devil and Daniel Webster', code: 'the-devil-and-daniel-webster' },
    { quote: '“The New Year, the year 1969, arrived. Rocket ships flashed on the sky, and helicopters whirred and flourished the warm California winds.” * The Shape of Things', code: 'the-shape-of-things' },
    { quote: '“When we live alone for long periods, we people the void with phantoms.” * The Horla', code: 'the-horla' },
    { quote: '“If all our dreams of quiet places are a folly and a snare, why have we such dreams?” * A Dream of Armageddon', code: 'a-dream-of-armageddon' },
    { quote: '“When he woke up the sensations of oil on his neck and the chill of peppermint about his lips had gone, but joy flooded his heart just as the day before.” * The Kiss', code: 'the-kiss' },
    { quote: '“Bad penny is it, you brass-buttoned son of iniquity?” * O’Halloran’s Luck', code: 'ohallorans-luck' },
    { quote: '“But now I am a great king, the people hound my track—With poison in my wine-cup, and daggers at my back.” * The Phoenix on the Sword', code: 'the-phoenix-on-the-sword' },
    { quote: '“Playing, sparkling, kindling with deep varicolored fires. A god was juggling diamonds.” * The Old City Of Marseilles', code: 'the-old-city-of-marseilles' },
    { quote: '“I did not shriek, but all the fiendish ghouls that ride the night-wind shrieked for me.” * The Outsider', code: 'the-outsider' },
    { quote: '“They had chained him down to things that are, and had then explained the workings of those things till mystery had gone out of the world.” * The Silver Key', code: 'the-silver-key' },
    { quote: '“I don’t believe in ghosts. If anything comes into my room I shall shoot it.” * Jerry Bundler', code: 'jerry-bundler' },
    { quote: '“There are jungles of the upper air, and there are worse things than tigers which inhabit them.” * The Horror of the Heights', code: 'the-horror-of-the-heights' },
    { quote: '“A man’s heart is in his back as well as in his arms and hands.” * No Place For a Woman', code: 'no-place-for-a-woman' },
    { quote: '“God alone understands the state of a man’s soul when he takes his own life; but men know nothing about it.” * A Story Without an End', code: 'a-story-without-an-end' },
    { quote: '“Then silence, and stillness, night were the universe.” * The Pit and the Pendulum', code: 'the-pit-and-the-pendulum' },
    { quote: '“Nothing existed that could not be bought by gold.” * The Treasure of Vasco Gomez', code: 'the-treasure-of-vasco-gomez' },
    { quote: '“When you’re young you’ve got the strength but you haven’t got the time. And when you’re old you’ve got time enough, but I’m always goin’ to sleep.” * Blossom and Fruit', code: 'blossom-and-fruit' },
    { quote: '“It’s terrible to be lawful married to a man you can’t even see, except now and then out of a window!” * The Sobbin’ Women', code: 'the-sobbin-women' },
    { quote: '“I came out of college about as well prepared for life as a snake is prepared to ride a bicycle.” * A House in the Country', code: 'a-house-in-the-country' },
    { quote: '“But I pulled a gun—a little Colt’s forty-four—and says, ‘This does my talkin’ for me.’” * The Night-Born', code: 'the-night-born' },
    { quote: '“Charity is the bone shared with the dog when you are just as hungry as the dog.” * Confession', code: 'confession' },
    { quote: '“He had always had perfect health, and his death, which occurred at the dinner table without a moment’s warning, surprised no one more than himself.” * A Bottomless Grave', code: 'a-bottomless-grave' },
    { quote: '“There are horrors beyond life’s edge that we do not suspect, and once in a while man’s evil prying calls them just within our range.” * The Thing on the Doorstep', code: 'the-thing-on-the-doorstep' },
    { quote: '“A picture with sound and color, reproducing faithfully the ordinary life about us, its tints and voices, even the noises of the city.” * The Chamber of Life', code: 'the-chamber-of-life' },
    { quote: '“Any other man would have been content to kill one or two of you and call it square, but to me that would have been a trivial revenge.” * The Man That Corrupted Hadleyburg', code: 'the-man-that-corrupted-hadleyburg' },
    { quote: '“Your beautiful form, your graceful walk, and your expressive eyes; surely with these you can enchain a man’s heart.” * The Little Mermaid', code: 'the-little-mermaid' },
    { quote: '“But does it matter whether it was a dream or reality, if the dream made known to me the truth?” * The Dream of a Ridiculous Man', code: 'the-dream-of-a-ridiculous-man' },
    { quote: '“I wish that you had nine lives, that I might take them all.” * Captain Rogers', code: 'captain-rogers' },
    { quote: '“The sun smouldered like a moon that had risen from a bath of blood.” * The Ninth Skeleton', code: 'the-ninth-skeleton' },
    { quote: '“What would be the going price for a drop of one’s blood—for a thousandth of a drop—if it carried virtual immortality?” * A Guest Of Ganymede', code: 'a-guest-of-ganymede' },
    { quote: '“Never have I lived in that dream, in that exaltation, in that state of madness into which the image of a woman casts us.” * Found on a Drowned Man', code: 'found-on-a-drowned-man' },
    { quote: '“No reason for insanity now. Shock? Silly. No shock. I’m to be rescued in six days. No shock to that.” * Asleep in Armageddon', code: 'asleep-in-armageddon' },
    { quote: '“And we come from the exulting South with joyous songs, with the dear bright sun in our souls.” * Black Fog', code: 'black-fog' },
    { quote: '“Their silver bells at eventide—Like fairies on the mountain side.” * Song of the Future', code: 'song-of-the-future' },
    { quote: '“I think the sun is a flower—That blooms for just one hour.” * All Summer in a Day', code: 'all-summer-in-a-day' },
    { quote: '“I wouldn’t go back there for the treasure of a dozen El Dorados.” * The Seed from the Sepulchre', code: 'the-seed-from-the-sepulchre' },
    { quote: '“The criminal is the creative artist; the detective only the critic.” * The Blue Cross', code: 'the-blue-cross' },
    { quote: '“A romance with an unknown woman, whose name he did not know, suddenly took possession of him.” * The Lady with the Dog', code: 'the-lady-with-the-dog' },
    { quote: 'Where the air was full of whispers and a pale, dead light came out from the rotten trunks that were lying on the ground.” * The White People', code: 'the-white-people' },
    { quote: '“Fate, that night, had strange, mysterious eyes. Fantastic cards lay up her sleeve.” * Egyptian Sorcery', code: 'egyptian-sorcery' },
    { quote: 'Most people were fools, and one government was as good as another for them.” * The Blood of the Martyrs', code: 'the-blood-of-the-martyrs' },
    { quote: '“This fellow has never tasted living blood yet, but when he does he will be a terror.” * The Brazilian Cat', code: 'the-brazilian-cat' },
    { quote: '“All that makes our life worth living comes unstriven for and free.” * Come-By-Chance', code: 'come-by-chance' },
    { quote: '“Even in Siberia there is happiness.” * In Exile', code: 'in-exile' },
    { quote: '“But I doubt if the men of the present—Are as good as the men of the past.” * A Voice from the Town', code: 'a-voice-from-the-town' },
    { quote: '“This man had changed the water in her veins to wine” * Her Letters', code: 'her-letters' },
    
    
    //They belong to the world on the other side of the dark curtain; and it is only by some queer mischance that a corner of that curtain is twitched aside for an instant.
    //Add only one I have actually read: "The great return"

    //
    // { quote: '“” * ', code: '' },


].sort(() => (Math.random() > 0.5) ? -1 : 1)


Quotes.quotes_i = 0//Math.floor(Math.random() * Quotes.quotes.length)


export default Quotes