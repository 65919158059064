
export const alphabetise = (a, b, options = {}) => {
    // Set the options using the nullish coalescing operator
    const ignoreA = options.ignore_a ?? true; // Ignore articles at the beginning of strings by default
    const emptyFirst = options.empty_first ?? false; // Put empty strings last by default
    const caseSensitive = options.case_sensitive ?? false; // Compare strings case-insensitively by default

    // Check for exact equality
    if (a === b) {
        return 0;
    }

    // Handle empty strings
    if (!a) {
        return emptyFirst ? -1 : 1;
    }

    if (!b) {
        return emptyFirst ? 1 : -1;
    }

    // Clip the strings if needed
    let clippedA = ignoreA ? a.replace(/['"“‘’”]/g, '').replace(/^(A|An|The)\s+/, '').replace(/^(\$|£|—)/, '') : a;
    let clippedB = ignoreA ? b.replace(/['"“‘’”]/g, '').replace(/^(A|An|The)\s+/, '').replace(/^(\$|£|—)/, '') : b;
    
    // .replace(/The Adventure of /,'')

    // Compare the clipped strings
    if (clippedA.localeCompare(clippedB, undefined, { sensitivity: caseSensitive ? 'case' : 'base' }) === 0) {
        return 0;
    }

    return clippedA.localeCompare(clippedB, undefined, { sensitivity: caseSensitive ? 'case' : 'base' });
};

export const num_compare = (a, b, options = {}) => {
    const emptyFirst = options.empty_first ?? false;

    if (a == b) {
        return 0;
    }

    a = Number(a);
    b = Number(b);

    if (typeof a !== 'number' || isNaN(a)) {
        return emptyFirst ? -1 : 1;
    } else if (typeof b !== 'number' || isNaN(b)) {
        return emptyFirst ? 1 : -1;
    } else {
        return a > b ? 1 : -1;
    }
};

export const bool_compare = (a, b) => {
    return a === b ? 0 : a ? 1 : -1;
}