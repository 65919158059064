import m, { redraw } from 'mithril'
import radio from './radio'


const Component = {
    oninit: (vnode) => {
        vnode.state.open_dyslexia_enabled = localStorage.getItem('dyslexia') == 'y'
        vnode.state.night_mode_enabled = localStorage.getItem('dark_mode') == 'true'
        vnode.state.hide_ratings_enabled = localStorage.getItem('hide_ratings_enabled') == 'true'
    },

    oncreate: (vnode) => {
        let listener = function (e) {
            if (!document.getElementById('settings_menu')) {
                window.removeEventListener('click', listener)
                return
            }
            if (document.getElementById('settings_menu').contains(e.target)) {
                // Clicked in box
            } else {
                vnode.attrs.close()
                redraw()
                window.removeEventListener('click', listener)
            }
        }
        window.addEventListener('click',
            listener
        );

    },

    view: function (vnode) {

        return [
            m('.settings_menu',
                {
                    id: 'settings_menu'
                },

                m('.settings_option',
                    {
                        onclick: () => {
                            vnode.state.open_dyslexia_enabled = !vnode.state.open_dyslexia_enabled
                            localStorage.setItem('dyslexia', vnode.state.open_dyslexia_enabled ? 'y' : 'n')

                            if (vnode.state.open_dyslexia_enabled) vnode.attrs.turn_on_dyslexica_font()
                            else vnode.attrs.turn_off_dyslexica_font()

                        }
                    },

                    m('.header', 'Open Dyslexic'),
                    m('', {
                        class: vnode.state.open_dyslexia_enabled ? 'active' : 'inactive'
                    }, vnode.state.open_dyslexia_enabled ? 'Enabled' : 'Disabled'),
                    m(radio, {
                        active: vnode.state.open_dyslexia_enabled,
                    }),
                ),
                
                m('br'),

                m('.settings_option',
                    {
                        onclick: () => {
                            vnode.state.night_mode_enabled = !vnode.state.night_mode_enabled
                            localStorage.setItem('dark_mode', vnode.state.night_mode_enabled ? 'true' : 'n')
                            vnode.attrs.change_dark_mode(vnode.state.night_mode_enabled)
                        }
                    },
                    m('.header', 'Night Mode'),
                    m('', {
                        class: vnode.state.night_mode_enabled ? 'active' : 'inactive'
                    }, vnode.state.night_mode_enabled ? 'Enabled' : 'Disabled'),
                    m(radio, {
                        active: vnode.state.night_mode_enabled,
                    })
                ),

                m('br'),

                m('.settings_option',
                    {
                        onclick: () => {
                            vnode.state.hide_ratings_enabled = !vnode.state.hide_ratings_enabled
                            localStorage.setItem('hide_ratings_enabled', vnode.state.hide_ratings_enabled ? 'true' : 'n')
                            vnode.attrs.change_hide_ratings_enabled(vnode.state.hide_ratings_enabled)
                        }
                    },
                    m('.header', 'Hide Ratings'),
                    m('', {
                        class: vnode.state.hide_ratings_enabled ? 'active' : 'inactive'
                    }, vnode.state.hide_ratings_enabled ? 'Enabled' : 'Disabled'),
                    m(radio, {
                        active: vnode.state.hide_ratings_enabled,
                    })
                ),
            )
        ]
    }
}


export default Component