
import m from 'mithril'

import Genres from '../../models/Genres'
import Stories from '../../models/Stories'

const image_base = 'https://libraryofshortstories.s3.ap-southeast-2.amazonaws.com/small/'


const story_block = {
    oninit: vnode => {
        if (vnode.attrs.story) vnode.state.story = vnode.attrs.story
        else {
            Stories.fetch_story_metadata_delay(vnode.attrs.code, story => {
                vnode.state.story = story
                m.redraw()
            })
        }
    },

    onupdate: vnode => {
        // console.log(1)
        if (vnode.attrs.code && vnode.state.story && vnode.attrs.code != vnode.state.story.code && !vnode.state.loading) {
            // console.log(2)

            vnode.state.loading = true

            Stories.fetch_story_metadata_delay(vnode.attrs.code, story => {
                vnode.state.story = story
                vnode.state.loading = false

                m.redraw()
            })
        }

    },

    view: vnode => {
        let story = vnode.state.story || vnode.attrs.story

        return [
            m('a.basic_story_tile',
                {
                    href: story ? ('/onlinereader/' + story.code) : undefined
                },
                story ? m('img.box_cover_image', {
                    loading: 'lazy',
                    src: image_base + story?.image,
                    style: story.genres ? Genres.get_style(Genres.list.find(g => g.code == story?.genres[0]).hue) : '',
                    alt: story.image_alt
                }) : [],
                m('.image_shade', {
                    style: (story?.genres && Genres.list.find(g => g.code == story?.genres[0]) ?

                        (
                            ('background:' + 'linear-gradient(0deg, ' + Genres.list.find(g => g.code == story?.genres[0]).colour + ' 0 60%, black 100%)')
                        )
                        : undefined
                    )
                }),

                m('.basic_story_tile_border'),

                story ? [
                    m('.basic_story_tile_title',
                        story.title,

                        m('.basic_story_tile_author', story.author) ,
                    )
                ]
                    : []
            )
        ]
    }
}

export default story_block