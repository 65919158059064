//Model for stories

import { redraw } from "mithril"

const delay_timer = 50

const Stories = {
    loaded: false,
    reduced_list_loaded: false,

    reduced_list: [],
    list: [],

    individual_dictionary: {},
    individual_dictionary_loading: {},
    individual_dictionary_callbacks: {},

    fetchtxt: (storycode, cb, error_cb = () => { }) => {

        let url = "/storiesstring/" + storycode + '.txt'

        fetch(url)
            .then(json_result => json_result.json())

            .then((result) => {

                cb(result.story)
            }).catch(err => {
                console.error(err)
                error_cb()
            })
    },

    fetchtxt_gzip: (storycode, cb = () => { }, error_cb = () => { }) => {
        let url = "/storiesgzip/" + storycode + '.txt'

        fetch(url)
            .then(result => {
                if (result.ok) {
                    result.text()
                        .then((result) => {
                            if (result) {
                                cb(result)
                            }
                            else {
                                Stories.fetchtxt(storycode, cb, error_cb)
                            }
                        })
                }
                else {
                    Stories.fetchtxt(storycode, cb, error_cb)
                }
            })
    },

    load: (cb = () => { }, hard_load = false) => {
        if (!hard_load && Stories.list && Stories.list.length > 0) return cb()

        if (Stories.loading_list) {
            Stories.callback_queue = Stories.callback_queue || []
            Stories.callback_queue.push(cb)
            return
        }
        else {

            Stories.loading_list = true
            //LOAD

            fetch('/storylist')
                .then(json_result => json_result.json())

                .then(stories => {

                    Stories.loading_list = false
                    Stories.loaded = true
                    Stories.list = stories
                    blurb_and_pic_fix()
                    cb()
                    if (Stories.callback_queue) {
                        Stories.callback_queue.forEach(waiting_callback => {
                            waiting_callback()
                        })
                        Stories.callback_queue = []
                    }

                    redraw()
                }).catch(err => {
                    console.error(err)
                })
        }
    },

    fetch_story_metadata: (code, cb, delay = false, failcb = () => {}) => {

        if (Stories.individual_dictionary[code]) return cb(Stories.individual_dictionary[code])

        else if (Stories.individual_dictionary_loading[code]) {
            Stories.individual_dictionary_callbacks[code] = Stories.individual_dictionary_callbacks[code] || []
            Stories.individual_dictionary_callbacks[code].push(cb)
            return
        }

        else if (delay) {
            return Stories.fetch_story_metadata_delay(code, cb)
        }

        else {
            Stories.individual_dictionary_loading[code] = true
            fetch('/storymetadata/' + code)
                .then(json_result => json_result.json()).then((result) => {
                    Stories.individual_dictionary[code] = result
                    Stories.individual_dictionary_loading[code] = true

                    cb(result)

                    if (Stories.individual_dictionary_callbacks[code]) {
                        Stories.individual_dictionary_callbacks[code].forEach(waiting_cb => waiting_cb(result))
                        Stories.individual_dictionary_callbacks[code] = []
                    }
                })
                .catch(() => {
                if(failcb) failcb()
            })
        }
    },


    fetch_group_metadata: (code_list, cb) => {

        code_list.forEach(code => {
            Stories.individual_dictionary_loading[code] = true
        })

        fetch('/story_group_metadata', {
            method: 'POST',
            body: JSON.stringify(code_list),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            }

        })
            .then(json_result => json_result.json())

            .then(results => {

                if (!results.error) {
                    results.stories.forEach(story => {
                        Stories.individual_dictionary[story.code] = story
                    })
                    cb(results.stories)
                    results.stories.forEach(story => {

                        if (Stories.individual_dictionary_callbacks[story.code]) {
                            Stories.individual_dictionary_callbacks[story.code].forEach(waiting_cb => waiting_cb(story))
                            Stories.individual_dictionary_callbacks[story.code] = []
                        }
                    })
                }
            })
    },

    metadata_delay_requests: [],
    metadata_delay_cbs: {},
    metadata_delay_timer: false,

    fetch_story_metadata_delay: (code, cb) => {

        if (Stories.metadata_delay_timer) clearTimeout(Stories.metadata_delay_timer)
        if (!Stories.metadata_delay_requests.includes(code)) {
            Stories.metadata_delay_requests.push(code)

        }
        Stories.metadata_delay_cbs[code] = Stories.metadata_delay_cbs[code] || []
        Stories.metadata_delay_cbs[code].push(cb)
        Stories.metadata_delay_timer = setTimeout(() => {
            let stories = Stories.metadata_delay_requests
            let story_cbs = Stories.metadata_delay_cbs
            Stories.metadata_delay_requests = []
            Stories.metadata_delay_cbs = {}
            Stories.metadata_delay_timer = false
            Stories.fetch_group_metadata(stories, (stories_metadata => {
                stories_metadata.forEach(story => {
                    story_cbs[story.code].forEach(cb => cb(story))
                })
            }))
        }, delay_timer);
    },


    get: () => {
        if (Stories.list) return Stories.list
        else { console.error('Stories not loaded') }
        return []
    },

    load_reduced: (cb = () => { }) => {
        if (Stories.reduced_list_loaded) {
            return cb(Stories.reduced_list)
        }

        if (!Stories.reduced_list_loading) {
            Stories.reduced_list_loading = true
            fetch('/storylist-reduced')
                .then(json_result => json_result.json())

                .then(stories => {
                    Stories.reduced_list = stories
                    Stories.reduced_list_loaded = true
                    blurb_and_pic_fix_reduced()
                    cb(Stories.reduced_list)
                    if (Stories.reduced_lists_callback_queue) {
                        Stories.reduced_lists_callback_queue.forEach(waiting_callback => {
                            waiting_callback(Stories.reduced_list)
                        })
                        Stories.reduced_lists_callback_queue = []
                    }
                })
        }
        else {
            Stories.reduced_lists_callback_queue = Stories.reduced_lists_callback_queue || []
            Stories.reduced_lists_callback_queue.push(cb)
            return
        }
    }

}

const blurb_and_pic_fix = () => {
    Stories.get().forEach(s => {
        s.picture = 'https://libraryofshortstories.s3.ap-southeast-2.amazonaws.com/small/' + s.picture
        s.blurb = s.blurb || ''
    })
}

const blurb_and_pic_fix_reduced = () => {
    Stories.reduced_list.forEach(s => {
        s.picture = 'https://libraryofshortstories.s3.ap-southeast-2.amazonaws.com/small/' + s.picture
        s.blurb = s.blurb || ''
    })
}

export default Stories