import m from 'mithril'

import { facebook, reddit, link } from './icons'

const sharelinks = {
    view: vnode => {

        let text_link = vnode.attrs.link || document.URL

        let link_text = encodeURIComponent(vnode.attrs.link || document.URL)

        return [
            m("ul", {
                class: "share-buttons d_flex flex_wrap",
                "data-source": "simplesharingbuttons.com"
            },
                [
                    m("li",
                        m("a.external_link", {
                            href: "https://www.facebook.com/sharer/sharer.php?u=" + link_text + "&quote=",
                            // "title": "Share on Facebook",
                            target: "_blank",
                            onclick: "window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(" + link_text + ") + '&quote=' + encodeURIComponent(" + link_text + ")); return false;"
                        },
                            m(facebook),
                            m('.text.', 'Share on Facebook')
                        )
                    ),

                    m("li",
                        m("a.external_link", {
                            href: "https://www.reddit.com/submit?url=" + link_text + "&title=" + vnode.attrs.title,
                            target: "_blank",
                            // "title": "Submit to Reddit",
                            onclick: "window.open('https://www.reddit.com/submit?url=' + encodeURIComponent(" + link_text + ") + '&title=' +  encodeURIComponent(document.title)); return false;"
                        },
                            m(reddit),
                            m('.text.', 'Share on Reddit')

                        )
                    ),


                    m("li",
                        m("button.button_link.remove_button_styling", {
                            // title: "Copy link",
                            onclick: (e) => {
                                try {
                                    navigator.clipboard.writeText(text_link);
                                } catch (error) {
                                    console.error(error)
                                }
                                vnode.state.link_copied = true
                                setTimeout(() => {
                                    vnode.state.link_copied = false
                                    m.redraw()
                                }, 2000);
                            }
                        },
                            m(link, { class: vnode.state.link_copied ? 'flash' : '' }),
                            m('.text', m('u', vnode.state.link_copied ? 'Copied!' : 'Copy Link'))
                        )
                    )
                ]
            )
        ]
    }
}

export default sharelinks