import m from 'mithril'
import Menu from '../../models/Menu'

const breadcrumbs = {

    view: vnode => {
        let links = vnode.attrs.links
        let title = vnode.attrs.title
        links = links ?? Menu.get_page().breadcrumb_links ?? []
        title = title ?? Menu.get_page().title ?? ''

        return [
            m('nav.breadcrumbs',
                {
                    'aria-label': 'Breadcrumb',
                    itemscope: true,
                    itemtype: 'https://schema.org/BreadcrumbList',
                },

                m('ol',
                    links.map((link, i) =>
                        m(breadcrumb, { link: link, position: i + 1 })
                    ),
                    m(final_breadcrumb, { title: title, position: links.length + 1 })
                )
            )
        ]
    }
}

const breadcrumb = {
    view: vnode => {

        let breadcrumb_page = Menu.get_page(vnode.attrs.link)

        return [
            m('li.breadcrumb', {
                itemprop: 'itemListElement',
                itemscope: true,
                itemtype: 'https://schema.org/ListItem'
            },
                m('a', {
                    itemprop: 'item',
                    itemid: vnode.attrs.link,
                    href: vnode.attrs.link,
                },
                    m('span',
                        {
                            itemprop: 'name',
                        },
                        breadcrumb_page?.title
                    )
                ),
                m('meta', {
                    itemprop: 'position',
                    content: vnode.attrs.position
                })

            )
        ]
    }
}

const final_breadcrumb = {
    view: vnode => {
        return [
            m('li.breadcrumb', {
                itemprop: 'itemListElement',
                itemscope: true,
                itemtype: 'https://schema.org/ListItem'
            },
                m('span.final_breadcrumb',

                    {
                        itemprop: 'name',
                        itemid: window.location.pathname,

                    },
                    vnode.attrs.title ? vnode.attrs.title : m('.word_shimmer.matching_half_shimmer', '-')
                ),
                m('meta', {
                    itemprop: 'position',
                    content: vnode.attrs.position
                })
            )
        ]
    }
}

export default breadcrumbs